import { graphql } from 'gatsby';
import EducationPage from '../views/education';

export default EducationPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    articles: allContentfulArticle(sort: { order: DESC, fields: publishDate }) {
      edges {
        node {
          title
          category
          slug
          titleInfo
          publishDate
          authors {
            title
            file {
              url
            }
          }
          thumbnail {
            title
            file {
              url
            }
          }
          readTime
          node_locale
        }
      }
    }

    workshops: allContentfulWarsztaty {
      edges {
        node {
          title
          type
          link
          data
          createdAt
          thumbnail {
            title
            file {
              url
            }
          }
          node_locale
        }
      }
    }

    interviews: allContentfulWywiady {
      edges {
        node {
          title
          type
          createdAt
          link
          thumbnail {
            title
            file {
              url
            }
          }
          node_locale
        }
      }
    }
  }
`;
